import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchVideoById } from '../api/videoApi';

interface VideoData {
    id: string;
    title: string;
    videoUrl: string;
}

const VideoPlayer: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Get the video ID from the URL
    const [videoData, setVideoData] = useState<VideoData | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!id) {
            return
        }

        const loadVideo = async () => {
            try {
                const data = await fetchVideoById(id);
                console.log(data);
                setVideoData(data);
            } catch (err) {
                setError('Error fetching video');
            }
        };

        loadVideo();
    }, [id]);

    const downloadVideo = () => {
        if (!videoData) {
            return;
        }

        const link = document.createElement('a');
        link.href = videoData.videoUrl;
        link.download = `${videoData.title}.mp4`; // Default file name (you can change the extension as needed)
        link.click();
    };


    if (error) {
        return <div>{error}</div>;
    }

    if (!videoData) {
        return <div className="">Loading...</div>;
    }

    return (
        <div className="flex justify-center items-center flex-col w-full h-screen relative">
            <div>
                <div className="flex justify-between mb-2">
                    <h3 className="text-[18px] mb-1 font-bold">{videoData.title}</h3>
                    <button onClick={downloadVideo}
                            className="bg-blue-600 text-cyan-50 px-3 rounded-sm">
                        Download Video
                    </button>
                </div>
                <div className="w-[65vw] aspect-[800/450]">
                    <iframe
                        src={`${videoData.videoUrl}`}
                        style={{ width: '100%', height: '100%' }}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title={videoData.title}
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;